export const styleCols = [
  {
    prop: 'styleName',
    label: '款式',
    minWidth: '200'
  },
  {
    prop: 'count',
    label: '数量',
    minWidth: '200'
  },
  {
    prop: 'amount',
    label: '货款',
    minWidth: '200'
  }
]
